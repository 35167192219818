<template>
    <div class="container">
        <div v-if="!onWebsite" class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <Breadcrumb
                    :pages="[
                        {
                            name: 'Resource List',
                            href: `/resources`,
                            current: false,
                        },
                        { name: 'Create Resource', href: null, current: true },
                    ]"
                />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
        <div class="mt-8 flex flex-col">
            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <form action="#" @submit.prevent="saveResource">
                    <div
                        :class="{
                            'shadow sm:rounded-md sm:overflow-hidden':
                                !onWebsite,
                        }"
                    >
                        <div
                            :class="{
                                'bg-white py-6 px-4 space-y-6 sm:p-6':
                                    !onWebsite,
                            }"
                        >
                            <div class="grid grid-cols-6 gap-6">
                                <!-- Website -->
                                <div
                                    v-if="!onWebsite"
                                    class="col-span-6 sm:col-span-3"
                                >
                                    <label
                                        class="block text-sm font-medium text-gray-700"
                                        >Website <Asterisk/></label
                                    >
                                    <select
                                        v-model="form.website_id"
                                        class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2.5 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        @change="getGroups()"
                                    >
                                        <option value="">...</option>
                                        <option
                                            v-for="website in websites"
                                            :key="website"
                                            :value="website.id"
                                        >
                                            {{ website.name }}
                                        </option>
                                    </select>
                                    <p
                                        v-if="errors.website_id"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.website_id[0] }}
                                    </p>
                                </div>

                                <!-- Title -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="title"
                                            v-model="form.title"
                                            :ErrorMessage="
                                                errors.title
                                                    ? errors.title[0]
                                                    : null
                                            "
                                            labelText="Title"
                                            placeholder="Title"
                                            asterisk
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.title"
                                            label="Title"
                                            asterisk
                                        />
                                        <AppInputError
                                            v-if="errors.title"
                                            :message="errors.title[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Type  -->
                                <div class="col-span-6 sm:col-span-3">
                                    <label
                                        v-if="!onWebsite"
                                        class="form-input-label"
                                        for="type_id"
                                        >Type <Asterisk/></label
                                    >
                                    <MultiSelect
                                        id="type_id"
                                        ref="multiSelect"
                                        v-model="form.resource_type_id"
                                        :on-website="onWebsite"
                                        :options="types"
                                        label="name"
                                        placeholder="Select Resource Type *"
                                        trackBy="name"
                                        required
                                        valueProp="id"
                                    />
                                    <p
                                        v-if="errors.resource_type_id"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.resource_type_id[0] }}
                                    </p>
                                </div>

                                <!-- author -->
                                <div class="col-span-6 sm:col-span-3">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="author"
                                            v-model="form.author"
                                            :ErrorMessage="
                                                errors.author
                                                    ? errors.author[0]
                                                    : null
                                            "
                                            labelText="Author"
                                            placeholder="Author"
                                            asterisk
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.author"
                                            label="Author"
                                            asterisk
                                        />
                                        <AppInputError
                                            v-if="errors.author"
                                            :message="errors.author[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Description -->
                                <div class="col-span-6">
                                    <fieldset>
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="street-address"
                                            >Description</label
                                        >
                                        <AppTextEditor
                                            v-model="form.description"
                                        />
                                        <p
                                            v-if="errors.description"
                                            class="mt-2 text-sm text-red-500"
                                        >
                                            {{ errors.description[0] }}
                                        </p>
                                    </fieldset>
                                </div>

                                <!-- summary -->
                                <div class="col-span-6 sm:col-span-6">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="summary"
                                            v-model="form.summary"
                                            :ErrorMessage="
                                                errors.summary
                                                    ? errors.summary[0]
                                                    : null
                                            "
                                            labelText="Summary"
                                            placeholder="Summary"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.summary"
                                            label="Summary"
                                        />
                                        <AppInputError
                                            v-if="errors.summary"
                                            :message="errors.summary[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Publish at -->
                                <div
                                    v-if="
                                        userRole != 'site users' &&
                                        hasAccessToAdminPanel
                                    "
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="published"
                                            v-model:checked="form.is_published"
                                            name="is_published"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="published"
                                            >Publish Now</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.is_published"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.is_published[0] }}
                                    </p>
                                </div>

                                <!-- Featured in Home Page -->
                                <div
                                    v-if="!onWebsite && hasAccessToAdminPanel"
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="featured"
                                            v-model:checked="form.featured"
                                            name="featured"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="featured"
                                            >Featured in Home Page</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.featured"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.featured[0] }}
                                    </p>
                                </div>

                                <!-- Featured in Group -->
                                <div
                                    v-if="
                                        userRole != 'site users' &&
                                        hasAccessToAdminPanel
                                    "
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="featured_group"
                                            v-model:checked="
                                                form.group_featured
                                            "
                                            name="featured_group"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="featured_group"
                                            >Featured in group</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.group_featured"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.group_featured[0] }}
                                    </p>
                                </div>

                                <!-- From United Way -->
                                <div
                                    v-if="hasAccessToAdminPanel"
                                    class="col-span-3 sm:col-span-3"
                                >
                                    <div class="flex items-center space-x-4">
                                        <AppCheckbox
                                            id="from_united_way"
                                            v-model:checked="
                                                form.from_united_way
                                            "
                                            name="from_united_way"
                                        />
                                        <label
                                            class="block text-sm font-medium text-gray-700"
                                            for="from_united_way"
                                            >From United Way</label
                                        >
                                    </div>
                                    <p
                                        v-if="errors.from_united_way"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.from_united_way[0] }}
                                    </p>
                                </div>

                                <!-- Cover Photo -->
                                <div class="col-span-6">
                                    <DropFile
                                        :file="form.cover"
                                        customCoverLocation
                                        @changeCoverLocation="
                                            ($event) =>
                                                (form.cover_location = $event)
                                        "
                                        @fileSelected="onSelectFile"
                                    />
                                    <p
                                        v-if="errors.cover"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.cover[0] }}
                                    </p>
                                </div>

                                <!-- Alternative Text-->
                                <div class="col-span-6 sm:col-span-6">
                                    <template v-if="!onWebsite">
                                        <input-component
                                            id="alt"
                                            v-model="form.alt"
                                            :ErrorMessage="
                                                errors.alt
                                                    ? errors.alt[0]
                                                    : null
                                            "
                                            labelText="Alternative Text"
                                            placeholder="Alternative Text"
                                        />
                                    </template>
                                    <template v-else>
                                        <AppInput
                                            v-model="form.alt"
                                            label="Alternative Text"
                                        />
                                        <AppInputError
                                            v-if="errors.alt"
                                            :message="errors.alt[0]"
                                            class="mt-2"
                                        />
                                    </template>
                                </div>

                                <!-- Group -->
                                <div v-if="!onWebsite" class="col-span-6">
                                    <label
                                        class="form-input-label"
                                        for="group_id"
                                        >Group</label
                                    >
                                    <MultiSelect
                                        id="group_id"
                                        ref="multiSelect"
                                        v-model="groupsId"
                                        :options="groups"
                                        :searchable="true"
                                        label="name"
                                        mode="tags"
                                        placeholder="Select Group"
                                        trackBy="name"
                                        valueProp="id"
                                    />
                                    <p
                                        v-if="errors.group_id"
                                        class="mt-2 text-sm text-red-500"
                                    >
                                        {{ errors.group_id[0] }}
                                    </p>
                                </div>

                                <!-- File Attachment-->
                                <div class="col-span-6 sm:col-span-6">
                                    <FileAttachments
                                        id="Files"
                                        v-model="form.files"
                                        :error-message="fileAttachmentsErrors"
                                        label="File Attachment"
                                    />
                                </div>

                                <!-- Embed Vedio -->
                                <div
                                    :class="{ 'p-4': !onWebsite }"
                                    class="bg-gray-50 rounded col-span-6 sm:col-span-6"
                                >
                                    <label
                                        class="form-input-label"
                                        for="group_id"
                                        >Embed Video</label
                                    >
                                    <div class="py-6 space-y-6">
                                        <div class="grid grid-cols-6 gap-6">
                                            <!-- Name -->
                                            <div
                                                class="col-span-6 sm:col-span-3"
                                            >
                                                <template v-if="!onWebsite">
                                                    <input-component
                                                        id="embed_name"
                                                        v-model="
                                                            form.embed_name
                                                        "
                                                        :ErrorMessage="
                                                            errors.embed_name
                                                                ? errors
                                                                      .embed_name[0]
                                                                : null
                                                        "
                                                        labelText="Name"
                                                        placeholder="Name"
                                                    />
                                                </template>
                                                <template v-else>
                                                    <AppInput
                                                        v-model="
                                                            form.embed_name
                                                        "
                                                        label="Name"
                                                    />
                                                    <AppInputError
                                                        v-if="errors.embed_name"
                                                        :message="
                                                            errors.embed_name[0]
                                                        "
                                                        class="mt-2"
                                                    />
                                                </template>
                                            </div>

                                            <!-- Embed Video -->
                                            <div
                                                class="col-span-6 sm:col-span-3"
                                            >
                                                <template v-if="!onWebsite">
                                                    <input-component
                                                        id="embed-video"
                                                        v-model="
                                                            form.embed_path
                                                        "
                                                        :ErrorMessage="
                                                            errors.embed_path
                                                                ? errors
                                                                      .embed_path[0]
                                                                : null
                                                        "
                                                        labelText="Embed Video"
                                                        placeholder="Embed Video"
                                                    />
                                                </template>
                                                <template v-else>
                                                    <AppInput
                                                        v-model="
                                                            form.embed_path
                                                        "
                                                        label="Embed Video"
                                                    />
                                                    <AppInputError
                                                        v-if="errors.embed_path"
                                                        :message="
                                                            errors.embed_path[0]
                                                        "
                                                        class="mt-2"
                                                    />
                                                    <p
                                                        class="mt-2 text-sm text-gray-400"
                                                    >
                                                        <span
                                                            class="font-medium"
                                                            >Youtube:</span
                                                        >
                                                        https://www.youtube.com/watch?v=000000001
                                                    </p>
                                                    <p
                                                        class="mt-2 text-sm text-gray-400"
                                                    >
                                                        <span
                                                            class="font-medium"
                                                            >Vimeo:</span
                                                        >
                                                        https://vimeo.com/000000001
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Audience -->
                                <div class="col-span-6">
                                    <fieldset>
                                        <legend
                                            class="text-base font-medium text-gray-900 mb-2"
                                        >
                                            Audience
                                        </legend>
                                        <MultiCheckbox
                                            v-model="form.audience_id"
                                            :error="
                                                errors.audience_id
                                                    ? errors.audience_id[0]
                                                    : null
                                            "
                                            :options="audiences"
                                        />
                                    </fieldset>
                                </div>

                                <!-- Subject Area -->
                                <div class="col-span-6">
                                    <fieldset>
                                        <legend
                                            class="text-base font-medium text-gray-900 mb-2"
                                        >
                                            Subject Area
                                        </legend>
                                        <MultiCheckbox
                                            v-model="form.subject_area_id"
                                            :error="
                                                errors.subject_area_id
                                                    ? errors.subject_area_id[0]
                                                    : null
                                            "
                                            :options="subjectAreas"
                                        />
                                    </fieldset>
                                </div>

                                <!-- Category -->
                                <div class="col-span-6">
                                    <fieldset>
                                        <legend
                                            class="text-base font-medium text-gray-900 mb-2"
                                        >
                                            Category
                                        </legend>
                                        <MultiCheckbox
                                            v-model="form.category_id"
                                            :error="
                                                errors.category_id
                                                    ? errors.category_id[0]
                                                    : null
                                            "
                                            :options="categories"
                                        />
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                        <!-- Submit button -->
                        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                :class="{
                                    'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                                        !onWebsite,
                                    'button primary': onWebsite,
                                    'disabled:!cursor-wait': isDisabled,
                                }"
                                :disabled="isDisabled"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import InputComponent from '@/components/shared/InputComponent';
import MultiSelect from '@vueform/multiselect';
import AppCheckbox from '@/components/shared/Checkbox';
import DropFile from '@/components/shared/DropFile';
import store from '@/store';
import Breadcrumb from '@/components/shared/Breadcrumb';
import request from '@/environment/request';
import AppTextEditor from '@/components/shared/TextEditor';
import MultiCheckbox from '@/components/shared/MultiCheckbox';
import audiencesService from '@/services/audience';
import subjectAreasService from '@/services/subject-area';
import categoriesService from '@/services/category';
import userService from '@/services/user';
import FileAttachments from './partial/FileAttachments.vue';
import permsMixin from '@/services/permsMixin';
import Asterisk from "@/components/shared/Asterisk";

// Imports for website section
import AppInput from '@js/Components/Shared/Input';
import AppInputError from '@js/Components/Shared/InputError';

const errors = ref({});
const fileAttachmentsErrors = ref([]);
const groups = ref([]);
const groupsId = ref([]);
const types = ref([]);
const cover = ref(null);
const audiences = ref([]);
const subjectAreas = ref([]);
const categories = ref([]);
const router = useRouter();

const form = reactive({
    title: '',
    cover: null,
    files: {},
    author: '',
    alt: '',
    summary: '',
    audience_id: [],
    group_id: [],
    website_id: '',
    description: '',
    featured: false,
    subject_area_id: [],
    category_id: [],
    group_featured: false,
    from_united_way: false,
    resource_type_id: null,
    is_published: false,
    embed_name: '',
    embed_path: '',
    cover_location: 0,
});

const websites = ref([]);
const perms = ref([]);

/**
 * This prop is used in resource.blade.php that is used in Groups
 */
const props = defineProps({
    onWebsite: Boolean,
    isPublished: Boolean,
    group: Object,
    userRole: String,
});

onMounted(() => {
    request(!props.onWebsite)
        .get('resource-types')
        .then((response) => (types.value = response.data));

    userService.getWebsites('me', { checkAdminAccess: false }).then((res) => {
        websites.value = res;
        form.website_id = websites.value[0].id;

        getGroups();
    });

    audiencesService.getAll().then((data) => {
        audiences.value = data;
    });

    getSubjectAreas(props.onWebsite ? props.group.id : null);
    getCategories(props.onWebsite ? props.group.id : null);
});

const isDisabled = ref(false);

// Get subject areas base on group
function getSubjectAreas(groupId) {
    subjectAreasService.getAll(groupId).then((data) => {
        subjectAreas.value = data;
        form.subject_area_id = [];
    });
}

// Get categories base on group
function getCategories(groupId) {
    categoriesService.getAll(groupId).then((data) => {
        categories.value = data;
        form.category_id = [];
    });
}

// Store resource
function saveResource() {
    isDisabled.value = true;
    if (props.userRole == 'site users') {
        console.log(props.isPublished);
        form.is_published = props.isPublished;
    }
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let formData = new FormData();

    // Check if files selected
    if (form.files) {
        for (let index = 0; index < form.files.length; index++) {
            formData.append('files[]', form.files[index]);
        }
    }

    formData.append(
        'website_id',
        props.onWebsite ? `${props.group.website_id}` : form.website_id
    );
    formData.append('author', form.author);
    formData.append('title', form.title);
    formData.append('summary', form.summary);
    formData.append('embed_name', form.embed_name);
    formData.append('embed_path', form.embed_path);
    formData.append('alt', form.alt);
    formData.append('cover', form.cover ? form.cover : '');
    formData.append(
        'group_id',
        props.onWebsite ? props.group.id : groupsId.value
    );
    formData.append('description', form.description);
    formData.append('featured', props.onWebsite ? `false` : form.featured);
    formData.append('from_united_way', form.from_united_way);
    formData.append('group_featured', form.group_featured);
    formData.append(
        'resource_type_id',
        form.resource_type_id ? form.resource_type_id : ''
    );
    formData.append('category_id', form.category_id);
    formData.append('audience_id', form.audience_id);
    formData.append('is_published', form.is_published);
    formData.append('subject_area_id', form.subject_area_id);
    formData.append('cover_location', form.cover_location);
    request(!props.onWebsite)
        .post(`resources`, formData, config)
        .then((response) => {
            let msg = 'New resource has been successfully created.';
            if (!props.isPublished) {
                msg = 'The new resource will be published after admin approval';
            }
            store.commit('displaySuccessModal', msg);
            if (props.onWebsite) {
                _toast.success({
                    title: 'Created resource',
                    message: msg,
                });
                setTimeout(
                    () =>
                        (location.href = `/group/${props.group.slug}/resource`),
                    5000
                );
            } else {
                router.push({ path: `/resources` });
            }
        })
        .catch((err) => {
            isDisabled.value = false;
            if (err.response && err.response.status == 422) {
                errors.value = err.response.data.errors;

                for (const key in errors.value) {
                    if (key.includes('files.')) {
                        fileAttachmentsErrors.value.push(errors.value[key]);
                    }
                }
            }
        });
}

function selectedCover($event) {
    const formData = new FormData();
    formData.append('file', $event.target.files[0]);
    const headers = { 'Content-Type': 'multipart/form-data' };
    request(!props.onWebsite)
        .post('upload', formData, { headers })
        .then((res) => {
            cover.value = res.data.path;
            form.cover = cover.value;
        })
        .catch((error) => (errors.value = error.response.data.errors));
}

// Select resource cover
function onSelectFile(files) {
    form.cover = files[0];
}

// Get groups base on website
function getGroups() {
    request(!props.onWebsite)
        .get(`get-groups?website_id=${form.website_id}`)
        .then((res) => {
            groups.value = res.data.data;
            form.group_id = '';
        });
}

watch(
    () => groupsId.value,
    () => {
        getSubjectAreas(groupsId.value);
        getCategories(groupsId.value);
    }
);

watchEffect(() => {
    form.title.length > 3
        ? (form.alt = `The cover image for ${form.title}`)
        : (form.alt = '');
});

const { hasAccessToAdminPanel } = permsMixin.setup();
</script>
